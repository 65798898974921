import React, { useRef } from "react";
import FooterB from "./FooterB"
import Formulario from "./Formulario";
import PreCadastro from "./PreCadastro";
import HeaderB from "./HeaderB";

const Register = () => {
const inicioRef = useRef(null);
  const formularioRef = useRef(null);
  const scrollToComponent = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

    return (<> 
    <HeaderB scrollToComponent={scrollToComponent} refs={{ inicioRef, formularioRef }} />
    <PreCadastro scrollToComponent={scrollToComponent} refs={{ inicioRef, formularioRef }}/>
    <div ref={formularioRef}>  
    <Formulario/>
    </div>
   
    <FooterB/>
    
    </>)
}

export default Register;