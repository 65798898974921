import React from 'react';

const Mercado = () => {
  return (
    <div className="md:h-[100vh] flex flex-col md:flex-row">
      <div className="p-8 flex flex-col">
        <a href="#" className="text-verde text-lg font-medium">M E R C A D O</a>
        <a className="text-3xl md:text-6xl font-medium mt-4">
          Entenda <a className='text-green-500'> como funciona </a> o mercado de distribuição de medicamentos
        </a>
        <a href="#" className="text-black text-lg mt-4">
          O mercado de distribuição de medicamentos no Brasil movimenta bilhões de reais anualmente, com um crescimento constante devido ao aumento da demanda por produtos farmacêuticos. No entanto, a complexidade dos sistemas atuais resulta em perda de eficiência e aumento de custos operacionais.
        </a>
      </div>

      <div className="w-full p-4">

        <div className="flex flex-col mb-4 md:flex-row md:space-x-12 space-y-4 md:space-y-0">
          <div className="flex flex-col w-full md:w-64 h-64 bg-verde rounded-2xl items-center justify-center text-center">
            <p className="text-6xl font-bold text-white">8%</p>
            <p className="text-2xl font-bold text-white">crescimento</p>
            <p className="text-2xl font-bold text-white">anual</p>
          </div>
          <div className="flex flex-col w-full md:w-64 h-64 bg-verde rounded-2xl items-center justify-center text-center">
            <p className="text-5xl font-bold text-white">R$ 200 bilhões</p>
            <p className="text-2xl font-bold text-white">faturamento</p>
            <p className="text-2xl font-bold text-white">anual</p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:space-x-12 space-y-4 md:space-y-0">
          <div className="flex flex-col w-full md:w-64 h-64 bg-verde border-2 rounded-2xl items-center justify-center text-center">
            <p className="text-6xl font-bold text-white">2.000+</p>
            <p className="text-2xl font-bold text-white">Número de</p>
            <p className="text-2xl font-bold text-white">Distribuidoras</p>
          </div>
          <div className="flex flex-col w-full md:w-64 h-64 bg-verde rounded-2xl items-center justify-center text-center">
            <p className="text-6xl font-bold text-white">80.000+</p>
            <p className="text-2xl font-bold text-white">Número de</p>
            <p className="text-2xl font-bold text-white">Farmácias</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mercado;
