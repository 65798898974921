import React from 'react';
import Woman from '../assets/images/young-woman-pharmacist-pharmacy 1.png';

function PreCadastro({ scrollToComponent, refs }) {
  return (
    <div>
     
      <section className="bg-gradient-to-r from-[#EBFFE3] via-[#FFFFFF] to-[#EBFFE3] ">
        <div className="container mx-auto flex flex-col md:flex-row items-center px-4">
          <div className="md:w-1/2 text-center md:text-left mb-10 md:mb-0">
            <h2 className="text-2xl font-medium text-verde uppercase mb-2">
              Faça seu pré-cadastro!
            </h2>
            <h1 className="text-3xl md:text-5xl font-bold text-verde2 mb-4 leading-tight">
              Transforme <span className='text-black'> seu </span>  negócio <span className='text-black'>  e venda mais com nosso </span>  <span className='text-verde'> programa para parceiros! </span>
            </h1>
            <p className="text-xl text-gray-700 mb-6">
              Faça o pré-cadastro para usufruir de todas as vantagens do nosso app.
            </p>
            <div className="flex justify-center md:justify-start">
              <button onClick={() =>  scrollToComponent(refs.formularioRef)}  className="bg-green-500 text-white px-6 py-3 rounded-full font-semibold hover:bg-green-600">
                Quero me inscrever agora mesmo
              </button>
            </div>
          </div>
          <div className="md:w-1/2 flex justify-center">
            <img src={Woman} alt="Parceiro" className="w-3/4 md:w-full h-auto" />
          </div>
        </div>
      </section>

      
      
    </div>
  );
}

export default PreCadastro;
