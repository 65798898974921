import React from "react";

const Formulario = () => {
    return (<>
    <div className="w-full h-2.5 bg-green-500"></div>

<section className="bg-white py-10">
  <div className="container mx-auto max-w-4xl px-4">
    <h2 className="text-center text-verde text-lg font-medium mb-2">
      PRÉ-CADASTRO
    </h2>
    <h3 className="text-center text-3xl font-bold text-gray-700 mb-8">
      Preencha o <span className="text-green-500">formulário</span> abaixo!
    </h3>

    <form className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div className="flex flex-col">
        <label htmlFor="cep" className="text-gray-700 font-semibold mb-2">CEP</label>
        <input
          id="cep"
          type="text"
          placeholder="Digite o seu CEP"
          className="border border-gray-300 p-4 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
        />
      </div>

      <div className="flex flex-col">
        <label htmlFor="estado" className="text-gray-700 font-semibold mb-2">Estado</label>
        <select
          id="estado"
          className="border border-gray-300 p-4 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
        > <option value="">Selecione seu estado</option>
          <option value="AC">Acre</option>
          <option value="AL">Alagoas</option>
          <option value="AP">Amapá</option>
          <option value="AM">Amazonas</option>
          <option value="BA">Bahia</option>
          <option value="CE">Ceará</option>
          <option value="DF">Distrito Federal</option>
          <option value="ES">Espírito Santo</option>
          <option value="GO">Goiás</option>
          <option value="MA">Maranhão</option>
          <option value="MT">Mato Grosso</option>
          <option value="MS">Mato Grosso do Sul</option>
          <option value="MG">Minas Gerais</option>
          <option value="PA">Pará</option>
          <option value="PB">Paraíba</option>
          <option value="PR">Paraná</option>
          <option value="PE">Pernambuco</option>
          <option value="PI">Piauí</option>
          <option value="RJ">Rio de Janeiro</option>
          <option value="RN">Rio Grande do Norte</option>
          <option value="RS">Rio Grande do Sul</option>
          <option value="RO">Rondônia</option>
          <option value="RR">Roraima</option>
          <option value="SC">Santa Catarina</option>
          <option value="SP">São Paulo</option>
          <option value="SE">Sergipe</option>
          <option value="TO">Tocantins</option>
          <option value="EX">Estrangeiro</option>
        </select>
      </div>

      <div className="flex flex-col">
        <label htmlFor="cidade" className="text-gray-700 font-semibold mb-2">Cidade</label>
        <input
          id="cidade"
          type="text"
          placeholder="Digite sua cidade"
          className="border border-gray-300 p-4 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
        />
      </div>

      <div className="flex flex-col">
        <label htmlFor="numero-residencia" className="text-gray-700 font-semibold mb-2">Número da Residência</label>
        <input
          id="numero-residencia"
          type="text"
          placeholder="Digite o número da residência"
          className="border border-gray-300 p-4 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
        />
      </div>

      <div className="flex flex-col">
        <label htmlFor="cpf-cnpj" className="text-gray-700 font-semibold mb-2">CPF ou CNPJ</label>
        <input
          id="cpf-cnpj"
          type="text"
          placeholder="Digite seu CPF/CNPJ"
          className="border border-gray-300 p-4 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
        />
      </div>

      <div className="flex flex-col">
        <label htmlFor="nome" className="text-gray-700 font-semibold mb-2">Nome Completo</label>
        <input
          id="nome"
          type="text"
          placeholder="Digite seu nome ou nome fantasia da empresa"
          className="border border-gray-300 p-4 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
        />
      </div>

      <div className="flex flex-col">
        <label htmlFor="email" className="text-gray-700 font-semibold mb-2">E-mail</label>
        <input
          id="email"
          type="email"
          placeholder="Digite seu e-mail"
          className="border border-gray-300 p-4 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
        />
      </div>

      <div className="flex flex-col">
        <label htmlFor="celular" className="text-gray-700 font-semibold mb-2">Celular</label>
        <input
          id="celular"
          type="text"
          placeholder="Digite seu número de celular"
          className="border border-gray-300 p-4 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
        />
      </div>

      <div className="flex flex-col">
        <label htmlFor="senha" className="text-gray-700 font-semibold mb-2">Senha</label>
        <input
          id="senha"
          type="password"
          placeholder="Crie uma senha"
          className="border border-gray-300 p-4 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
        />
      </div>

      <div className="flex flex-col">
        <label htmlFor="confirmar-senha" className="text-gray-700 font-semibold mb-2">Confirmar Senha</label>
        <input
          id="confirmar-senha"
          type="password"
          placeholder="Confirme sua senha"
          className="border border-gray-300 p-4 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
        />
      </div>

      <div className="flex justify-center md:col-span-2">
        <button
          type="submit"
          className="bg-green-500 text-white px-8 py-3 rounded-full font-semibold hover:bg-green-600">
          Cadastrar
        </button>
      </div>
    </form>
  </div>
</section>
    
    </>) };

export default Formulario