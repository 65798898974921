import facebook from "../assets/images/fb.svg"
import instagram from "../assets/images/insta.svg"
import whatsapp from "../assets/images/wpp.png"
import youtube from "../assets/images/yt.png"
import '../index.css';

const Footer = ({ scrollToComponent, refs }) => {


  

  return (
    <footer className="bg-verde text-white py-6 relative">

      <div className="hidden md:flex flex-wrap justify-center  space-x-4 text-center">
        <a className="m-2 font-poppins  hover:text-verde2  md:text-sm houver:cursor-pointer " onClick={() => scrollToComponent(refs.inicioRef)}>INÍCIO</a>
        <span className="m-2 text-base  md:text-sm">|</span>
        <a className="m-2 font-poppins hover:text-verde2 text-base md:text-sm houver:cursor-pointer " onClick={() => scrollToComponent(refs.sobreRef)} >O QUE É?</a>
        <span className="m-2 text-base md:text-sm">|</span>
        <a className="m-2 font-poppins hover:text-verde2 text-base md:text-sm houver:cursor-pointer " onClick={() => scrollToComponent(refs.mercadoRef)} >MERCADO</a>
        <span className="m-2 text-base md:text-sm">|</span>
        <a className="m-2 font-poppins hover:text-verde2 text-base md:text-sm houver:cursor-pointer " onClick={() => scrollToComponent(refs.doresRef)}> DORES</a>
        <span className="m-2 text-base md:text-sm">|</span>
        <a className="m-2 font-poppins hover:text-verde2 text-base md:text-sm houver:cursor-pointer " onClick={() => scrollToComponent(refs.beneficiosRef)}>BENEFÍCIOS</a>
      </div>

<div className="md:hidden flex flex-col items-center space-y-2 mt-10">
        <a className="text-sm  md:text-base font-poppins" onClick={() => scrollToComponent(refs.inicioRef)} >INÍCIO</a>
        <a> _</a>
        <a className="text-sm  md:text-base font-poppins" onClick={() => scrollToComponent(refs.sobreRef)} >O QUE É?</a>
        <a> _</a>
        <a className="text-sm  md:text-base font-poppins" onClick={() => scrollToComponent(refs.mercadoRef)}>MERCADO</a>
        <a> _</a>
        
        <a className="text-sm  md:text-base font-poppins" onClick={() => scrollToComponent(refs.doresRef)} >DORES</a>
        <a> _</a>
        <a className="text-sm  md:text-base font-poppins" onClick={() => scrollToComponent(refs.beneficiosRef)}>BENEFÍCIOS</a>
      </div>
      
      <div className="mx-auto  flex inten-center justify-center space-x-4 my-4 max-w-xs md:max-w-sm"> 
        <a href='https://www.facebook.com/buyfamaoficial/' target="_blank" rel="noopener noreferrer"> <img src={facebook}/> </a>
      <a href='https://www.instagram.com/buyfarmaoficial/'target="_blank" rel="noopener noreferrer"> <img src={instagram }/></a> 
       <a href='https://wa.me/5521920097959' target="_blank" rel="noopener noreferrer"> <img src={whatsapp}/></a> 
       <a href='https://www.youtube.com/@buyfarmaoficial' target="_blank" rel="noopener noreferrer"> <img className='w-8 h-8' src={youtube}/></a> 
      </div>

      <div className="text-center mt-4">
        <p className="mb-4   text-xs md:text-xs"> <a className=' font-bold'> Buy Farma </a> &copy; 2024 - Todos os direitos reservados.</p>
      </div>
    </footer>
  );
};

export default Footer;
