import React from 'react';

const Dores = () => {
  return (
    <div className="bg-gray-100 md:h-min-screen py-8 px-4 md:py-16 md:px-8 flex flex-col items-center justify-center ">

      <div className="w-full flex flex-col items-center justify-center text-center mb-8 md:mb-48 ">
        <div className="w-full md:w-auto">
          <p className="text-center  text-verde font-medium break-words mb-9">D O R E S</p>
        </div>
        <div className="w-full md:w-3/4 lg:w-1/2">
          <p className="text-4xl sm:text-5xl md:text-6xl font-bold text-black whitespace-normal">
            A Dor que a <span className="text-verde">Buy Farma</span>
          </p>
          <p className="text-4xl sm:text-5xl md:text-6xl font-bold text-black whitespace-normal mt-2">
            sana no mercado
          </p>
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 md:space-x-8">

        <div className="w-full md:w-80 lg:w-96 h-auto md:h-80 rounded-extra-large border-4 border-verde p-6 md:p-8 flex flex-col items-start justify-center">
          <div className="flex flex-row items-start">
            <p className="text-7xl md:text-8xl font-extrabold text-verde mr-4">01</p>
            <div>
              <p className="font-bold text-xl mt-2  md:mt-5  md:text-2xl text-verde">Fragmentação</p>
              <p className="font-bold text-xl md:text-2xl text-verde">de Sistemas</p>
            </div>
          </div>
          <p className="text-base md:text-lg mt-2">Dificuldade em consolidar informações de produtos e fornecedores.</p>
        </div>


        <div className="w-full md:w-80 lg:w-96 h-auto md:h-80 rounded-extra-large border-4 border-verde p-6 md:p-8 flex flex-col items-start justify-center">
          <div className="flex flex-row items-start">
            <p className="text-7xl  md:text-8xl font-blackest text-verde mr-4">02</p>
            <div>
              <p className="font-bold text-xl mt-2  md:mt-5 md:text-2xl text-verde">Processos</p>
              <p className="font-bold text-xl md:text-2xl text-verde">Ineficientes</p>
            </div>
          </div>
          <p className="text-base md:text-lg mt-2">Tempo excessivo gasto em negociações e compras.</p>
        </div>

        <div className="w-full md:w-80 lg:w-96 h-auto md:h-80 rounded-extra-large border-4 border-verde p-6 md:p-8 flex flex-col items-start justify-center">
          <div className="flex flex-row items-start">
            <p className="text-7xl md:text-8xl font-extrabold text-verde mr-4">03</p>
            <div>
              <p className="font-bold text-xl mt-2 md:mt-5  md:text-2xl text-verde">Custos</p>
              <p className="font-bold text-xl md:text-2xl text-verde">Elevados</p>
            </div>
          </div>
          <p className="text-base md:text-lg mt-2">Maior custo operacional devido à complexidade dos sistemas.</p>
        </div>

      </div>
    </div>
  );
};

export default Dores;
