import React from 'react';
import fullImage from '../assets/images/Component 2.png';
import img1 from '../assets/images/Rectangle 7.png';
import img2 from '../assets/images/Rectangle 8.png';
import img3 from '../assets/images/Rectangle 9.png';
import img4 from '../assets/images/Rectangle 10.png';

const Sobre = () => {
  return (
    <div className="min-h-screen font-poppins">
      <div className="w-full h-2.5 bg-green-500"></div>

  
      <div className="flex flex-col md:flex-row mt-2">
        <div className="flex-1 flex flex-col items-center order-2 md:order-1 p-4">
          <div className="w-full flex justify-center mb-10">
            <img
              src={fullImage}
              alt="Imagem Principal"
              className="w-54 md:w-84 h-auto object-cover"
            />
          </div>
          <div className="w-54 md:w-84 md:mt-2">
            <div className="grid grid-cols-2 gap-2">
              <img
                src={img1}
                alt="Imagem 1"
                className="w-full h-auto object-cover"
              />
              <img
                src={img2}
                alt="Imagem 2"
                className="w-full h-auto object-cover"
              />
              <img
                src={img3}
                alt="Imagem 3"
                className="w-full h-auto object-cover"
              />
              <img
                src={img4}
                alt="Imagem 4"
                className="w-full h-auto object-cover"
              />
            </div>
          </div>
        </div>

        <div className="flex-1 bg-pink p-8 order-1 md:order-2">

          <div className="text-left mb-8">
            <h3 className="text-1xl font-semibold text-verde mb-2">C O N H E Ç A <a className='ml-3 mr-2'> A </a> <a className='font-bold'>  B U Y <a className='ml-2 mr-2'> </a> F A R M A ! </a></h3>
            <h1 className="text-2xl md:text-4xl mb-4">Uma empresa</h1>
            <h1 className="text-2xl md:text-4xl mb-4">
              inovadora no setor de <span className="font-bold text-verde2">distribuição de medicamentos</span>!
            </h1>
            <p className="text-base md:text-lg">
              Criada para simplificar e otimizar a interação entre distribuidoras e farmácias. Com nossa plataforma integrada, buscamos eliminar a necessidade de múltiplos sistemas, oferecendo uma solução única e eficiente para compras e gestão de fornecedores.
            </p>
          </div>

          <div className='w-full bg-verde flex flex-col items-center justify-center py-8 px-4 sm:py-12 sm:px-6 md:py-16 md:px-8 lg:py-20 lg:px-12 xl:py-24 xl:px-16'>
            <div className="max-w-3xl text-start text-white">
              <div className="mb-4">
                <span className='text-xl md:text-xl font-bold'>Visão:</span>
                <p className='text-lg md:text-lg inline ml-2'>
                  Ser a plataforma líder de marketplace para distribuição de medicamentos, reconhecida pela inovação e excelência em serviços.
                </p>
              </div>
              <div className="mb-4">
                <span className='text-xl md:text-xl font-bold'>Missão:</span>
                <p className='text-lg md:text-lg inline ml-2'>
                  Facilitar a comunicação e a transação entre distribuidoras e farmácias, promovendo um mercado mais integrado e eficiente.
                </p>
              </div>
              <div className="mb-4">
                <span className='text-xl md:text-xl font-bold'>Valores:</span>
                <p className='text-lg md:text-lg inline ml-2'>
                  Inovação: Buscamos constantemente novas soluções tecnológicas para melhorar nossos serviços.
                </p>
              </div>
              <div className="mb-4">
                <span className='text-xl md:text-xl font-bold'>Transparência:</span>
                <p className='text-lg md:text-lg inline ml-2'>
                  Mantemos um ambiente de negócios claro e confiável.
                </p>
              </div>
              <div className="mb-4">
                <span className='text-xl md:text-xl font-bold'>Eficiência:</span>
                <p className='text-lg md:text-lg inline ml-2'>
                  Otimizamos processos para economizar tempo e recursos de nossos usuários.
                </p>
              </div>
              <div className="mb-4">
                <span className='text-xl md:text-xl font-bold'>Segurança:</span>
                <p className='text-lg md:text-lg inline ml-2'>
                  Garantimos a proteção de dados e transações em nossa plataforma.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sobre;
