import facebook from "../assets/images/fb.svg"
import instagram from "../assets/images/insta.svg"
import whatsapp from "../assets/images/wpp.png"
import youtube from "../assets/images/yt.png"
import '../index.css';

const FooterB = () => {


  

  return (
    <footer className="bg-verde text-white py-6 relative">

      


      <div className="mx-auto  flex inten-center justify-center space-x-4 my-4 max-w-xs md:max-w-sm"> 
        <a href='https://www.facebook.com/buyfamaoficial/' target="_blank" rel="noopener noreferrer"> <img src={facebook}/> </a>
      <a href='https://www.instagram.com/buyfarmaoficial/'target="_blank" rel="noopener noreferrer"> <img src={instagram }/></a> 
       <a href='https://wa.me/5521920097959' target="_blank" rel="noopener noreferrer"> <img src={whatsapp}/></a> 
       <a href='https://www.youtube.com/@buyfarmaoficial' target="_blank" rel="noopener noreferrer"> <img className='w-8 h-8' src={youtube}/></a> 
      </div>

      <div className="text-center mt-4">
        <p className="mb-4   text-xs md:text-xs"> <a className=' font-bold'> Buy Farma </a> &copy; 2024 - Todos os direitos reservados.</p>
      </div>
    </footer>
  );
};

export default FooterB;
