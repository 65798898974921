import React, { useRef } from "react";
import Inicio from "./Inicio";
import Sobre from "./Sobre";
import Beneficios from "./Beneficios";
import Mercado from "./Mercado";
import Dores from "./Dores";
import Footer from "./Footer";
import Header from "./Header";
const Home = () => {
  const inicioRef = useRef(null);
  const sobreRef = useRef(null);  
  const mercadoRef = useRef(null);
  const doresRef = useRef(null);
  const beneficiosRef = useRef(null);

  const scrollToComponent = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };


    return (
       
            <>
      <Header scrollToComponent={scrollToComponent} refs={{ inicioRef, sobreRef, mercadoRef, doresRef, beneficiosRef }}/>
      <div scrollToComponent={scrollToComponent} refs={{ inicioRef, sobreRef, mercadoRef, doresRef, beneficiosRef }} />

      <div ref={inicioRef}>
        <Inicio />
      </div>

      <div ref={sobreRef}>
        <Sobre />
      </div>

      <div ref={mercadoRef}>
        <Mercado />
      </div>

      <div ref={doresRef}>
        <Dores />
      </div>

      <div ref={beneficiosRef}>
        <Beneficios />
      </div>

      <div scrollToComponent={scrollToComponent} refs={{ inicioRef, sobreRef, mercadoRef, doresRef, beneficiosRef }}/>
   <Footer scrollToComponent={scrollToComponent} refs={{ inicioRef, sobreRef, mercadoRef, doresRef, beneficiosRef }}/>
        </>
    );  
}

export default Home;